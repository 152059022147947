import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { globalStyles } from '../styles'

import useSiteMetadata from '../hooks/use-site-metadata'
import { mediaQueries } from '../theme'
import SiteMetadata from './site-metadata'
import Header from './header'
import Footer from './footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1 1 auto;
  padding-top: ${p => p.theme.space[21]};

  ${mediaQueries.lg} {
    padding-top: ${p => p.theme.space[24]};
  }
`

export const BaseLayout = ({ children, location }) => (
  <Fragment>
    <Global styles={globalStyles} />
    <SiteMetadata location={location} />
    {children}
  </Fragment>
)

const Layout = ({
  children,
  location,
  withNav = true,
  withFooter = true,
  className,
}) => {
  const { title } = useSiteMetadata()
  return (
    <BaseLayout location={location}>
      <Wrapper className={className}>
        <Header withNav={withNav} siteTitle={title} />
        <Main>{children}</Main>
        {withFooter ? <Footer siteTitle={title} /> : null}
      </Wrapper>
    </BaseLayout>
  )
}

export default Layout
